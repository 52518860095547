import { ISTacticActionEnum } from '../enums';
import { ISTacticActionMessage } from '../models';

export const IS_TACTIC_ACTION_MESSAGE: ISTacticActionMessage = {
  success: {
    [ISTacticActionEnum.REMOVE_FROM_NEGATIVES]: 'Action undone successfully.',
  },
  error: {
    [ISTacticActionEnum.REMOVE_FROM_NEGATIVES]: 'There were some problems. Please try again later.',
  },
};
