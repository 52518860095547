import { ISMetricEnum } from '@ess/integrated-search/shared/utils';

import { ISTacticsEnum } from '../enums';
import { ISTacticChartSeries } from '../models';

export const IS_TACTICS_CHART_SERIES: { [key in ISTacticsEnum]: ISTacticChartSeries[] } = {
  [ISTacticsEnum.CHANNEL_OVERLAP]: [
    ISMetricEnum.COMBINED_IMPRESSIONS,
    ISMetricEnum.PPC_CONVERSION_RATE,
    ISMetricEnum.CONVERSIONS_VALUE,
    ISMetricEnum.PAID_IMPRESSIONS_SHARE,
    ISMetricEnum.PPC_CLICKS,
    ISMetricEnum.PPC_CONVERSIONS,
    ISMetricEnum.PPC_CPA,
    ISMetricEnum.PPC_CPC,
    ISMetricEnum.PPC_CTR,
    ISMetricEnum.PPC_IMPRESSIONS,
    ISMetricEnum.PPC_SPEND,
    ISMetricEnum.PPC_ROAS,
    ISMetricEnum.SEO_CLICKS,
    ISMetricEnum.SEO_CTR,
    ISMetricEnum.SEO_IMPRESSIONS,
    ISMetricEnum.SEO_POSITION,
    ISMetricEnum.MAX_IMPRESSIONS,
    ISMetricEnum.MAX_PAID_IMPRESSIONS_SHARE,
  ],
  [ISTacticsEnum.SEARCH_GAPS]: [
    // TODO - add when available
    // ISMetricEnum.CLICKS_COVERAGE,
    ISMetricEnum.COMBINED_IMPRESSIONS,
    ISMetricEnum.CONVERSIONS_VALUE,
    ISMetricEnum.PAID_IMPRESSIONS_SHARE,
    ISMetricEnum.PPC_IMPRESSIONS,
    ISMetricEnum.SEO_IMPRESSIONS,
    ISMetricEnum.SEO_POSITION,
    ISMetricEnum.MAX_IMPRESSIONS,
    ISMetricEnum.MAX_PAID_IMPRESSIONS_SHARE,
  ],
  [ISTacticsEnum.LANDING_PAGE_OPTIMISATION]: [
    // TODO: Uncomment when PPC_COST_SHARE metric is added
    // ISMetricEnum.PPC_COST_SHARE,
    ISMetricEnum.COMBINED_IMPRESSIONS,
    ISMetricEnum.PPC_CONVERSIONS,
    ISMetricEnum.CONVERSIONS_VALUE,
    ISMetricEnum.PPC_CTR,
    ISMetricEnum.PPC_IMPRESSIONS,
    ISMetricEnum.SEO_IMPRESSIONS,
    ISMetricEnum.PAID_IMPRESSIONS_SHARE,
    ISMetricEnum.PPC_CLICKS,
    ISMetricEnum.PPC_CPC,
    ISMetricEnum.PPC_ROAS,
    ISMetricEnum.PPC_SPEND,
    ISMetricEnum.MAX_IMPRESSIONS,
    ISMetricEnum.MAX_PAID_IMPRESSIONS_SHARE,
  ],
};
