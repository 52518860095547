import {
  ISChannelOverlapRecommendationEnum,
  ISGapsRecommendationEnum,
  ISOptimisationRecommendationEnum,
} from '../enums';

export const IS_TACTICS_RECOMMENDATION_LABELS: { [key: string]: string } = {
  [ISChannelOverlapRecommendationEnum.ADD_TO_NEGATIVES]: 'Add to negatives',
  [ISChannelOverlapRecommendationEnum.ADDED_TO_NEGATIVES]: 'Added to negatives',
  [ISOptimisationRecommendationEnum.CRITICAL]: 'Critical to optimise',
  [ISOptimisationRecommendationEnum.IMPORTANT]: 'Important to optimise',
  [ISOptimisationRecommendationEnum.MEDIUM]: 'Medium need to optimise',
  [ISOptimisationRecommendationEnum.OPTIMISED]: 'Optimised',
  [ISGapsRecommendationEnum.IMPROVE_SEO]: 'Improve SEO',
  [ISGapsRecommendationEnum.IMPROVE_PPC]: 'Improve PPC',
  [ISGapsRecommendationEnum.OPTIMISED_SEO]: 'SEO improved',
  [ISGapsRecommendationEnum.OPTIMISED_PPC]: 'PPC improved',
};
