import { SharedChartTypeEnum } from '@ess/shared/utils/enums';
import { SharedChartData, SharedLineChartData, SharedLineChartSeries, SharedTimespan } from '@ess/shared/utils/models';

import { ISTacticsApprovalsHelper } from './is-tactics-approvals.helper';

import { ISTacticsChartDefsConfig } from '../configs';
import { IS_TACTICS_CHART_SERIES } from '../consts';
import { ISTacticsEnum } from '../enums';
import { ISApprovals, ISRecommendation, ISTacticChartSeries } from '../models';

export class ISTacticsChartDataHelper {
  static getChartSeriesForMetrics(
    alignScales = true,
    data?: SharedChartData | null,
    metrics?: (ISTacticChartSeries | null)[],
  ): SharedChartData | null {
    return data && metrics?.length
      ? {
          ...data,
          data: {
            ...data.data,
            series: this.__prepareChartSeries(data.data, metrics, alignScales),
          },
        }
      : data ?? null;
  }

  static formatChartSeries<R extends ISRecommendation>(
    source: ISTacticsEnum,
    data: SharedLineChartData,
    approvals?: ISApprovals<R>[],
    timespan?: SharedTimespan,
  ): SharedChartData {
    const chartData = {
      type: SharedChartTypeEnum.LINE,
      data: {
        series: IS_TACTICS_CHART_SERIES[source].reduce<SharedLineChartSeries[]>((acc, serie) => {
          const dataSerie = data.series.find((s) => s.name === serie);
          !!dataSerie && acc.push(dataSerie);
          return acc;
        }, []),
        categories: data.categories,
      },
    };

    if (approvals?.length && timespan) {
      const {
        data: { series },
      } = chartData;

      series.push({
        data: [],
        color: '',
        markPoint: ISTacticsApprovalsHelper.mapApprovalsToChartMarkPoint<R>(approvals, timespan),
      });
    }
    return chartData;
  }

  private static __prepareChartSeries(
    data: SharedLineChartData,
    metrics: (ISTacticChartSeries | null)[],
    alignScales = true,
  ): SharedLineChartSeries[] {
    const series = data.series.filter((serie) => !serie.name || metrics.includes(serie.name as ISTacticChartSeries));

    series.sort((a, b) => {
      const indexA = metrics.indexOf(a.name as ISTacticChartSeries) ?? 0;
      const indexB = metrics.indexOf(b.name as ISTacticChartSeries) ?? 0;
      return indexA - indexB;
    });

    return series.map((serie) => {
      const metricIndex = metrics.indexOf(serie.name as ISTacticChartSeries) ?? 0;
      return {
        ...serie,
        ...(serie.name && {
          symbol: ISTacticsChartDefsConfig.symbols[metricIndex],
          yAxisIndex: alignScales && ISTacticsChartDefsConfig.isScaleAlignmentApplicable(metrics) ? 0 : metricIndex,
          itemStyle: { color: ISTacticsChartDefsConfig.axesColors[metricIndex] },
          lineStyle: { color: ISTacticsChartDefsConfig.axesColors[metricIndex] },
          id: ISTacticsChartDefsConfig.symbols[metricIndex] ?? serie.name,
        }),
      };
    });
  }
}
