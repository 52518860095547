import { ISTacticsEnum } from '../enums';
import { ISTacticsTooltipDictionary } from '../models';

export const IS_TACTICS_TOOLTIP_DICTIONARY: ISTacticsTooltipDictionary = {
  [ISTacticsEnum.CHANNEL_OVERLAP]: {
    tactic:
      'Channel Overlap tactic provides recommendations regarding adding search terms to negative keywords with high organic position and costly or ineffecive PPC performance.',
    timeFilter:
      'By applying time filter, you’ll see only those search terms that existed in selected time range. This affects results in recommendation cards, charts and table.',
  },
  [ISTacticsEnum.LANDING_PAGE_OPTIMISATION]: {
    tactic:
      'Landing Page Optimisation tactic provides recommendations regarding optimising pages for the keywords which 1. LP experience is below average or 2. LP experience is average and ad cost share is meaningful.',
    timeFilter:
      'By applying time filter, you’ll see only those keywords that existed in selected time range. This affects results in recommendation cards, charts and table.',
  },
  [ISTacticsEnum.SEARCH_GAPS]: {
    tactic:
      'Search Gaps tactic provides recommendations regarding optimising (SEO) or bidding (PPC) on certain search terms. Integrated search indicates the gap if Paid impressions share for given search term is 5% or lower.',
    timeFilter:
      'By applying time filter, you’ll see only those search terms that existed in selected time range. This affects results in recommendation cards, charts and table.',
  },
};
