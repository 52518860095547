import {
  ISChannelOverlapRecommendationEnum,
  ISGapsRecommendationEnum,
  ISOptimisationRecommendationEnum,
  ISTacticActionEnum,
  ISTacticsEnum,
} from '../enums';
import { ISTacticPerformanceDictionary } from '../models';

export const IS_TACTIC_PERFORMANCE_DICTIONARY: ISTacticPerformanceDictionary = {
  [ISTacticsEnum.CHANNEL_OVERLAP]: {
    title: 'Search term performance',
    entityMetrics: 'Search term details',
    entitiesName: 'Search terms',
    button: {
      [ISTacticActionEnum.REMOVE_FROM_NEGATIVES]: 'Undo',
      default: 'Mark as added to negatives',
    },
    recommendation: {
      [ISChannelOverlapRecommendationEnum.ADD_TO_NEGATIVES]:
        'SEO performance of this search term is good enough and PPC performance is costly or ineffective. It makes sense to consider adding this search term to negative keywords in Google Ads platform.\n\nThis recommendation is being done based on Channel Overlap tactic.',
      [ISChannelOverlapRecommendationEnum.NONE]:
        'Both SEO and PPC performances of this search term are good enough and effective. There’s no need to add this search term to negative keywords, although you may still do it.\n\nThis recommendation is being done based on Channel Overlap tactic.',
      [ISChannelOverlapRecommendationEnum.ADDED_TO_NEGATIVES]:
        'You have already added this search term to negative keywords.',
    },
    confirmation: {
      [ISTacticActionEnum.REMOVE_FROM_NEGATIVES]:
        "Undo if you marked certain search term as added to negatives by mistake and/or haven't performed this action in Google Ads.\n\nNo record will be left in Integrated Search Dashboard afterwards. A recommendation will be displayed again for this search term.",
      default:
        'Approving this recommendation means that you agree to perform the action. You need to go to Google Ads platform in order to add this search term to negative keywords (we recommend to add it on account level as exact match).\n\nIn Integrated Search this moment will be marked and you will be able to track it.',
    },
    confirmationTitle: {
      [ISTacticActionEnum.REMOVE_FROM_NEGATIVES]: 'Are you sure you want to undo marking as added to negatives?',
    },
    tooltip: {
      [ISTacticActionEnum.REMOVE_FROM_NEGATIVES]: true,
    },
    action: {
      [ISChannelOverlapRecommendationEnum.ADD_TO_NEGATIVES]: ISTacticActionEnum.ADD_TO_NEGATIVES,
      [ISChannelOverlapRecommendationEnum.ADDED_TO_NEGATIVES]: ISTacticActionEnum.REMOVE_FROM_NEGATIVES,
    },
    primaryAction: ISTacticActionEnum.ADD_TO_NEGATIVES,
    entityActionPath: 'search-terms:action',
  },
  [ISTacticsEnum.LANDING_PAGE_OPTIMISATION]: {
    title: 'Keyword performance',
    entityMetrics: 'Keyword details',
    entitiesName: 'Keywords',
    button: {
      default: 'Mark as optimised',
    },
    recommendation: {
      [ISOptimisationRecommendationEnum.NONE]:
        'Landing page experience for this keyword is above average or it was not specified in Google Ads. It does not make sense to consider optimising this keyword, although you may still do it.\n\nThis recommendation is being done based on Landing Page Optimisation tactic.',
      [ISOptimisationRecommendationEnum.OPTIMISED]: 'Landing page has been optimised for this keyword.',
      default:
        'Landing page experience for this keyword is below average or average and ad cost share is meaningful. It makes sense to consider optimising landing page for this keyword.\n\nThis recommendation is being done based on Landing Page Optimisation tactic.',
    },
    confirmation: {
      default:
        'Approving this recommendation means that this landing page has been already optimised for this keyword.\n\nIn Integrated Search this moment will be marked and you will be able to track it.',
    },
    entityActionPath: 'keywords:approve',
  },
  [ISTacticsEnum.SEARCH_GAPS]: {
    title: 'Search term performance',
    entityMetrics: 'Search term details',
    entitiesName: 'Search terms',
    button: {
      [ISTacticActionEnum.IMPROVE_PPC]: 'Mark as PPC improved',
      [ISTacticActionEnum.IMPROVE_SEO]: 'Mark as SEO improved',
    },
    recommendation: {
      [ISGapsRecommendationEnum.NONE]:
        "Paid impressions share for this search term is above 5% and there's no need to consider improving SEO/PPC for this search term, although you may still do it.\n\nThis recommendation is being done based on Search Gaps tactic.",
      [ISGapsRecommendationEnum.IMPROVE_PPC]:
        'Combined impressions share is 5% or below and search term difficulty is high. It makes sense to consider PPC optimisation of this search term.\n\nThis recommendation is being done based on Search Gaps tactic.',
      [ISGapsRecommendationEnum.IMPROVE_SEO]:
        'Combined impressions share is 5% or below and search term difficulty is low. It makes sense to consider SEO optimisation of this search term.\n\nThis recommendation is being done based on Search Gaps tactic.',
      [ISGapsRecommendationEnum.OPTIMISED_PPC]: 'You have already improved PPC of this search term.',
      [ISGapsRecommendationEnum.OPTIMISED_SEO]: 'You have already improved SEO of this search term.',
    },
    confirmation: {
      [ISTacticActionEnum.IMPROVE_PPC]:
        'Approving this recommendation means that you agree to perform the action. You need to go to Google Ads platform in order to make proper changes.\n\nIn Integrated Search this moment will be marked and you will be able to track it.',
      [ISTacticActionEnum.IMPROVE_SEO]:
        'Approving this recommendation means that you have already optimised SEO for this search term.\n\nIn Integrated Search this moment will be marked and you will be able to track it.',
    },
    action: {
      [ISGapsRecommendationEnum.IMPROVE_SEO]: ISTacticActionEnum.IMPROVE_SEO,
      [ISGapsRecommendationEnum.IMPROVE_PPC]: ISTacticActionEnum.IMPROVE_PPC,
    },
    primaryAction: ISTacticActionEnum.IMPROVE_SEO,
    secondaryAction: ISTacticActionEnum.IMPROVE_PPC,
    entityActionPath: 'search-terms:action',
  },
};
