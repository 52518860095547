import { ISMetricEnum } from '@ess/integrated-search/shared/utils';

import { ISTacticMetricTabConfig, ISTacticMetricTabs } from '../models';

export class ISTacticsInitialChartDefsHelper {
  static getInitialState(projectId?: number): ISTacticMetricTabs {
    return {
      projectId: projectId ?? -1,
      channelOverlap: {
        tabs: ISTacticsInitialChartDefsHelper.getChannelOverlapTabs(),
      },
      landingPageOptimisation: {
        tabs: ISTacticsInitialChartDefsHelper.getLandingPageOptimisationTabs(),
      },
      searchGaps: {
        tabs: ISTacticsInitialChartDefsHelper.getSearchGapsTabs(),
      },
    };
  }

  static getChannelOverlapTabs(): ISTacticMetricTabConfig[] {
    return [
      {
        title: 'Traffic-focused',
        charts: [
          {
            title: 'Impressions',
            metric_1: ISMetricEnum.COMBINED_IMPRESSIONS,
            metric_2: ISMetricEnum.PAID_IMPRESSIONS_SHARE,
            alignScales: false,
          },
          {
            title: 'SEO Clicks',
            metric_1: ISMetricEnum.SEO_CLICKS,
            metric_2: ISMetricEnum.SEO_CTR,
            alignScales: false,
          },
          {
            title: 'PPC Clicks',
            metric_1: ISMetricEnum.PPC_CLICKS,
            metric_2: ISMetricEnum.PPC_CTR,
            alignScales: false,
          },
        ],
      },
      {
        title: 'Conversion-focused',
        charts: [
          {
            title: 'Chart 1',
            metric_1: ISMetricEnum.PPC_CONVERSIONS,
            metric_2: null,
            alignScales: true,
          },
          {
            title: 'Chart 2',
            metric_1: ISMetricEnum.CONVERSIONS_VALUE,
            metric_2: null,
            alignScales: true,
          },
          {
            title: 'Chart 3',
            metric_1: ISMetricEnum.PPC_CONVERSION_RATE,
            metric_2: null,
            alignScales: true,
          },
        ],
      },
      {
        title: 'Revenue-focused',
        charts: [
          {
            title: 'Chart 1',
            metric_1: ISMetricEnum.PPC_ROAS,
            metric_2: null,
            alignScales: true,
          },
          {
            title: 'Chart 2',
            metric_1: ISMetricEnum.PPC_CPC,
            metric_2: null,
            alignScales: true,
          },
          {
            title: 'Chart 3',
            metric_1: ISMetricEnum.PPC_CPA,
            metric_2: null,
            alignScales: true,
          },
        ],
      },
      {
        title: 'Other',
        charts: [],
      },
    ];
  }

  static getSearchGapsTabs(): ISTacticMetricTabConfig[] {
    return [
      {
        title: 'Traffic-focused',
        charts: [
          {
            title: 'Overall Impressions',
            metric_1: ISMetricEnum.COMBINED_IMPRESSIONS,
            metric_2: ISMetricEnum.PAID_IMPRESSIONS_SHARE,
            alignScales: false,
          },
          {
            title: 'SEO Impressions',
            metric_1: ISMetricEnum.SEO_IMPRESSIONS,
            metric_2: ISMetricEnum.PPC_IMPRESSIONS,
            alignScales: true,
          },
          {
            title: 'SEO',
            metric_1: ISMetricEnum.SEO_POSITION,
            metric_2: null,
            alignScales: false,
          },
        ],
      },
      {
        title: 'Conversion-focused',
        charts: [
          {
            title: 'Chart 1',
            metric_1: ISMetricEnum.CONVERSIONS_VALUE,
            metric_2: null,
            alignScales: true,
          },
        ],
      },
      {
        title: 'Other',
        charts: [],
      },
    ];
  }

  static getLandingPageOptimisationTabs(): ISTacticMetricTabConfig[] {
    return [
      {
        title: 'Traffic-focused',
        charts: [
          {
            title: 'Impressions 1',
            metric_1: ISMetricEnum.SEO_IMPRESSIONS,
            metric_2: ISMetricEnum.PPC_IMPRESSIONS,
            alignScales: true,
          },
          {
            title: 'Impressions 2',
            metric_1: ISMetricEnum.COMBINED_IMPRESSIONS,
            metric_2: ISMetricEnum.PAID_IMPRESSIONS_SHARE,
            alignScales: false,
          },
          {
            title: 'Clicks',
            metric_1: ISMetricEnum.PPC_CLICKS,
            metric_2: ISMetricEnum.PPC_CTR,
            alignScales: false,
          },
        ],
      },
      {
        title: 'Conversion-focused',
        charts: [
          {
            title: 'Chart 1',
            metric_1: ISMetricEnum.PPC_CONVERSIONS,
            metric_2: null,
            alignScales: true,
          },
          {
            title: 'Chart 2',
            metric_1: ISMetricEnum.CONVERSIONS_VALUE,
            metric_2: null,
            alignScales: true,
          },
        ],
      },
      {
        title: 'Revenue-focused',
        charts: [
          {
            title: 'Chart 1',
            metric_1: ISMetricEnum.PPC_CPC,
            metric_2: null,
            alignScales: true,
          },
          {
            title: 'Chart 2',
            metric_1: ISMetricEnum.PPC_ROAS,
            metric_2: null,
            alignScales: true,
          },
        ],
      },
      {
        title: 'Other',
        charts: [
          {
            title: 'Chart 1',
            metric_1: ISMetricEnum.PPC_SPEND,
            metric_2: null,
            alignScales: true,
          },
          // TODO: Uncomment when AD_COST_SHARE is added to the enum
          // {
          //   title: 'Chart 2',
          //   metric_1: ISMetricEnum.AD_COST_SHARE,
          //   metric_2: null,
          //   alignScales: true,
          // },
        ],
      },
    ];
  }
}
