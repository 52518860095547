import {
  ISChannelOverlapRecommendationEnum,
  ISGapsRecommendationEnum,
  ISOptimisationRecommendationEnum,
  ISTacticsEnum,
} from '../enums';
import { ISRecommendation } from '../models';

export const IS_TACTICS_RECOMMENDATIONS: { [key in ISTacticsEnum]: ISRecommendation[] } = {
  [ISTacticsEnum.CHANNEL_OVERLAP]: Object.values(ISChannelOverlapRecommendationEnum),
  [ISTacticsEnum.SEARCH_GAPS]: Object.values(ISGapsRecommendationEnum),
  [ISTacticsEnum.LANDING_PAGE_OPTIMISATION]: Object.values(ISOptimisationRecommendationEnum),
};
