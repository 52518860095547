import { ISAdGroup, ISCampaign } from '@ess/integrated-search/shared/utils';

export class ISTacticsCampaignTooltipHelper {
  static adGroupTooltips(adGroups: ISAdGroup[]): string[] {
    return adGroups.map((adGroup) => `Ad group: ${adGroup.ad_group_name}\nID: ${adGroup.ad_group_id}`);
  }

  static campaignTooltips(campaigns: ISCampaign[]): string[] {
    return campaigns.map((campaign) => `Campaign: ${campaign?.campaign_name}\nID: ${campaign?.campaign_id}`);
  }
}
