import { YAXisOption } from 'echarts/types/dist/shared';

import {
  IS_METRICS_LABELS,
  IS_METRICS_UNITS,
  ISMetricUnitEnum,
  ISNumericMetricEnum,
} from '@ess/integrated-search/shared/utils';
import { SHARED_COLORS } from '@ess/shared/utils/consts';
import { essFormatDateHelper, essFormatNumber } from '@ess/shared/utils/helpers';
import { SharedChartOptions } from '@ess/shared/utils/models';

import { ISTacticChartSeries } from '../models';

export class ISTacticsChartDefsConfig {
  static readonly axesColors: string[] = [SHARED_COLORS.chart['50'], SHARED_COLORS.chart['400']];
  static readonly symbols = ['circle', 'diamond'];
  static readonly shapes: { [key: string]: string } = {
    circle: 'rounded-full',
    diamond: 'rotate-45',
  };

  static getTacticChartOptions(
    metrics: (ISTacticChartSeries | null)[],
    alignScales = true,
    currency?: string | null,
    missingDataWarning?: string | null,
    showTooltip?: boolean,
  ): SharedChartOptions {
    const yAxis = metrics
      .filter((_, index) => !(alignScales && this.isScaleAlignmentApplicable(metrics)) || index === 0)
      .map((metric, index) => {
        return this.__createYAxis(
          this.axesColors[index],
          metric ? IS_METRICS_UNITS[metric] : ISMetricUnitEnum.INTEGER,
          currency ?? undefined,
          !!metric,
        );
      });

    return {
      xAxis: {
        axisLabel: {
          formatter: (label: string) => essFormatDateHelper(label),
        },
      },
      yAxis,
      ...(showTooltip
        ? this.__getChartTooltip(currency ?? undefined, missingDataWarning)
        : { tooltip: { show: false } }),
    };
  }

  static isScaleAlignmentApplicable(metrics: (ISTacticChartSeries | null)[]): boolean {
    return !!metrics[0] && !!metrics[1] && IS_METRICS_UNITS[metrics[0]] === IS_METRICS_UNITS[metrics[1]];
  }

  private static __createYAxis(
    color: string,
    format: ISMetricUnitEnum = ISMetricUnitEnum.INTEGER,
    currency?: string,
    show = true,
  ): YAXisOption {
    return {
      type: 'value' as const,
      axisLabel: { color, formatter: this.__getValueFormatter(format, currency) },
      axisLine: { lineStyle: { color } },
      show,
      offset: 16,
      min: 0,
      max: (value: { min: number; max: number }) => {
        return value.max > 1 ? Math.ceil(value.max) : value.max;
      },
    };
  }

  private static __getValueFormatter(
    format: ISMetricUnitEnum = ISMetricUnitEnum.INTEGER,
    currencyCode?: string,
  ): (value: number) => string {
    switch (format) {
      case ISMetricUnitEnum.PERCENTAGE:
        return (value: number) =>
          essFormatNumber(value, {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            style: 'percent',
          });
      case ISMetricUnitEnum.CURRENCY:
        return (value: number) =>
          essFormatNumber(value, {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            style: 'currency',
            currencyDisplay: 'narrowSymbol',
            currency: currencyCode,
          });
      default:
        return (value: number) =>
          essFormatNumber(value, {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
    }
  }

  private static __getChartTooltip(currency?: string, missingDataWarning?: string | null): Partial<SharedChartOptions> {
    return {
      tooltip: {
        formatter: (params: any) => {
          let missingData = false;
          const series: TooltipSerie[] = (params as any[]).reduce<TooltipSerie[]>((arr, serie) => {
            const metric: ISNumericMetricEnum = serie.seriesName;
            const unit: ISMetricUnitEnum = IS_METRICS_UNITS[metric] ?? ISMetricUnitEnum.INTEGER;
            const valueFn: (value: number | null) => string | null = (value: number | null) =>
              value !== null && value !== undefined ? this.__getValueFormatter(unit, currency)(value) : null;
            const name: string = IS_METRICS_LABELS[metric] ?? serie.seriesName;

            const marker: string =
              '<div class="w-1.5 h-1.5 overflow-visible shrink-0 ' +
              this.shapes[serie.seriesId] +
              '" style="background-color: ' +
              serie.color +
              '"></div>';

            missingData = missingData || serie.value === null || serie.value === undefined;

            return arr.concat({
              valueFn,
              name,
              marker,
              value: serie.value,
            });
          }, []);

          const date = essFormatDateHelper(params.name ?? params[0]?.name ?? '', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          });

          return params.componentType === 'series' || params[0]?.componentType === 'series'
            ? `
            <div class="flex flex-col items-stretch gap-y-4" style="max-width: 13rem">
              <p class="text-label"><b>${date}</b></p>
              <div class="flex flex-col justify-start items-stretch gap-y-2">`.concat(
                ...series.map(
                  ({ name, value, valueFn, marker }) => `
                            <div class="flex justify-between items-center gap-x-4">
                              <div class="grow flex justify-start items-center gap-x-1">${marker}<span class="text-base-secondary">${name}</span></div>
                              <div class="shrink-0"><b>${valueFn(value) ?? 'NO DATA'}</b></div>
                            </div>
              `,
                ),
                '</div>',
                missingData && missingDataWarning
                  ? `<div style="text-wrap: wrap;">
                    ${missingDataWarning}
                  </div>
                  `
                  : '',
                '</div>',
              )
            : '';
        },
      },
    };
  }
}

type TooltipSerie = {
  valueFn: (value: number | null) => string | null;
  name: string;
  marker: string;
  value: number | null;
};
