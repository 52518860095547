import { ISTacticsEnum } from '../enums';

export const IS_TACTICS_CHART_MISSING_DATA_MESSAGE: { [key in ISTacticsEnum]: string } = {
  [ISTacticsEnum.CHANNEL_OVERLAP]:
    'The missing data is caused by extended processing times by Google or by lack of overlap to calculate. This is a common occurrence. Please select date ranges with complete data.',
  [ISTacticsEnum.SEARCH_GAPS]:
    'The missing data is caused by extended processing times by Google. This is a common occurrence. Please select date ranges with complete data.',
  [ISTacticsEnum.LANDING_PAGE_OPTIMISATION]:
    'The missing data is caused by extended processing times by Google. This is a common occurrence. Please select date ranges with complete data.',
};
