import {
  ISChannelOverlapRecommendationEnum,
  ISGapsRecommendationEnum,
  ISOptimisationRecommendationEnum,
} from '../enums';
import { ISRecommendation } from '../models';

export const IS_TACTICS_APPROVED_RECOMMENDATIONS: ISRecommendation[] = [
  ISChannelOverlapRecommendationEnum.ADDED_TO_NEGATIVES,
  ISOptimisationRecommendationEnum.OPTIMISED,
  ISGapsRecommendationEnum.OPTIMISED_PPC,
  ISGapsRecommendationEnum.OPTIMISED_SEO,
];
